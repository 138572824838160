.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.no-padding{
  padding: 0 !important;
}

.np-left {
  padding-left: 0 !important;
}

.np-right {
  padding-right: 0 !important;
}

.white-link{
  color: #ffffff;
  text-decoration: none;
}

.white-link:hover{
  color: #ffffff;
  text-decoration: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.euros-monitor {
  background-color: #00509b;
  padding: 30px;
  border-radius: 15px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


form {
  width: 100%;
}


.carousel-input-click2 {
  min-width: 40px;
  max-width: 60%;
  height: 40px;
  background: #fff;
  border: 1px solid #005492;
  border-radius: 5px;
  line-height: 40px !important;
  text-align: center;
  cursor: pointer;
  margin: 0 15px;
  padding: 0 15px;
}

.carousel-input-click-text2 {
  line-height: 40px !important;
  text-align: center;
  color: #005492;
  cursor: pointer;
  margin: 0 15px !important;
}

.carousel-input-click {
  min-width: 40px;
  height: 40px;
  background: #fff;
  border: 1px solid #005492;
  border-radius: 5px;
  line-height: 40px !important;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  margin: 0 15px;
  padding: 0 15px;
}

.carousel-input-click-text {
  line-height: 40px !important;
  text-align: center;
  color: #005492;
  cursor: pointer;
  display: inline-block;
  margin: 0 15px !important;
}

.swiper-container {
  width: 100%;
  height: 150px;
  background: #fff;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  color: #000;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}

.swiper-slide.swiper-slide-visible.swiper-slide-prev, .swiper-slide.swiper-slide-visible.swiper-slide-next {
color: slategray;
}
.swiper-slide.swiper-slide-active {
  border-bottom: 1px solid #005492;
  border-top: 1px solid #005492;
}

.gray-bg {
  background-color: #80808059;
  margin: 15px auto 30px;
  padding: 0 15px;
}

.gray-bg p {
  display: inline-block;
  margin: 5px auto;
  line-height: 26px;
}

.mode-icon {
  height: 30px;
  margin: 4px auto;
}

.gray-bg .mode-icon {
  cursor: pointer;
}

.setting-item {
  border-top: 1px solid #b3b3b3;
  border-bottom: 1px solid #b3b3b3;
  height: 70px;
}

.setting-item p {
  padding-left: 58px;
  line-height: 70px;
}

p.data-box {
  border-radius: 5px;
  border: 1px solid #b3b3b3;
  height: 50px;
  padding: 0 25px;
  width: auto;
  text-align: center;
  margin: 10px 10px;
  line-height: 50px;
  cursor: pointer;
  /* display: inline-block; */
  float: left;
}

.setting-item p.data-box:first-child {
  margin-right: 25px;
}

@keyframes pulsacja {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pulsowanie {
  animation: pulsacja 2s infinite;
}

/* ------ SCADA ------- */
.st0 {
  fill: #9fc9c7;
}
.st1 {
  fill: none;
  stroke: #f05b41;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.st1.red{
  fill: none;
  stroke: #f05b41;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.st1.blue{
  fill: none;
  stroke: #008AAA;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.st2 {
  fill: none;
  stroke: #ff0000;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.st2.red{
  fill: none;
  stroke: #ff0000;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.st2.blue{
  fill: none;
  stroke: #0252d1;
  stroke-width: 5;
  stroke-miterlimit: 10;
}

.st44, .st55, .st66 {
  stroke-width: 1 !important;
}

.st3 {
  fill: #808083 !important;
}
.st44 {
  fill: #59595c !important;
}
.st55 {
  fill: #b0dedb !important;
}
.st66 {
  fill: #d4ece9 !important;
}
.st77 {
  fill: #f05b41 !important;
}
.st8 {
  fill: #4d4d4d !important;
}
.st9 {
  fill: #e6e7e8 !important;
}
.st10 {
  fill: none;
  stroke: #d62027;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.st10.red{
  fill: none;
  stroke: #d62027;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.st10.blue{
  fill: none;
  stroke: #008AAA;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.st11 {
  fill: #d62027 !important;
}
.st11.red{
  fill: #d62027;
}
.st11.blue{
  fill: #008AAA;
}
.st12 {
  fill: none;
  stroke: #05bcd5;
  stroke-width: 5.1072;
  stroke-miterlimit: 10;
}
.st12.red {
  fill: none;
  stroke: #d62027;
  stroke-width: 5.1072;
  stroke-miterlimit: 10;
}
.st12.blue {
  fill: none;
  stroke: #05bcd5;
  stroke-width: 5.1072;
  stroke-miterlimit: 10;
}
.st13 {
  fill: #5e5e5e;
}
.st14 {
  fill: none;
  stroke: #ffffff;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
.st15 {
  fill: none;
  stroke: #e5e5e4;
  stroke-width: 4.303;
  stroke-miterlimit: 10;
}
.st16.green{
  fill: #009245;
}
.st16.orange{
  fill: #FF9515;
}
.st16.blue{
  fill: #008AAA;
}
.st17.orange{
  fill: #f7931e;
}
.st17.green{
  fill: #009245;
}
.st18 {
  fill: none;
  stroke: #ffffff;
  stroke-linecap: square;
  stroke-miterlimit: 22.926;
}
.st19 {
  fill: none;
  stroke: #ffffff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 22.9256;
}
.st20 {
  fill: none;
  stroke: #ffffff;
  stroke-miterlimit: 10;
}
.st21 {
  fill: #ffffff;
}

.text-scada {
  fill: #ffffff;
  font-size: 24px !important;
  font-family: "Geogrotesque-Regular" !important;
}

.text-scada.middle {
  font-size: 28px;
  font-weight: 600;
}

.text-scada.big {
  font-size: 32px;
  font-weight: 600;
}

.MuiDialog-root.quickinfo .MuiDialog-container.MuiDialog-scrollBody .MuiDialog-paperFullWidth {
  background: none transparent !important;
  box-shadow: none !important;
}

.MuiDialog-root.quickinfo .infobox {
  background: black;
  border-radius: 12px;
  padding: 20px 50px;
  width: 1024px;
  margin: 0 auto;
}

.MuiDialog-root.quickinfo .apla-scada {
  margin-top: 30px;
  padding: 50px 0 30px;
  background: url('./img/bg-scada.svg') no-repeat top center transparent;
  background-size: 1024px;
}

.MuiDialog-root.quickinfo .apla-scada .small-icon {
  margin: 30px auto 0;
  width: 80px;
}

.geo .st0{fill:#9FC9C7 !important;}
.geo .st1{fill:#B0DEDB !important; stroke: none !important;}
.geo .st2{fill:#D4ECE9 !important; stroke: none !important;}
.geo .st3{fill:#3FA9F5 !important;}
.geo .st4{fill:#F05B41 !important; stroke: none !important;}
.geo .st5{fill:none !important; stroke:#E5E5E4 !important; stroke-width:3.9204 !important; stroke-miterlimit:2.2252 !important;}
.geo .st6{ stroke: none !important;}
.geo .st6.orange{fill:#F7931E !important;}
.geo .st6.green{fill:#009245 !important;}
.geo .st7{fill:#D62027 !important;}
.geo .st8.orange{fill:#F7931E !important;}
.geo .st8.green{fill:#009245 !important;}
.geo .st8.blue{fill:#0071BC !important;}
.geo .st9{fill:#0071BC !important;}
.geo .st10{fill:#FF0000 !important; stroke: none !important;}
.geo .st11{fill:none !important; stroke:#D62027 !important; stroke-width:4.5553 !important; stroke-miterlimit:2.2252 !important;}
.geo .st12{fill:#FFFFFF !important; stroke: none !important;}
.geo .st13{fill:#808083 !important;}
.geo .st14{fill:#4D4D4D !important; stroke: none !important;}
.geo .st15{fill:#E6E7E8 !important; stroke: none !important;}
.geo .st16{fill:#59595C !important;}
.geo .st17{fill:none !important; stroke:#FFFFFF !important; stroke-width:1.3519 !important; stroke-linecap:square !important; stroke-miterlimit:2.2252 !important;}
.geo .st18{fill:none !important; stroke:#FFFFFF !important; stroke-width:1.3519 !important; stroke-linecap:round !important; stroke-linejoin:round !important; stroke-miterlimit:2.2252 !important;}
.geo .st19{fill:none !important; stroke:#FFFFFF !important; stroke-width:1.4369 !important; stroke-miterlimit:2.2252 !important;}

.text-scada.grey { fill:#5c5b5b;}
.text-scada.white { fill:rgb(255, 255, 255);}

.title-box {
  background-color: #f5f5f5; 
  padding: 5px;
  height: 35px;
  margin: 0;
}

.content-msg {
  padding: 5px;
}

.euros-box {
  background-color: #000;
  color: #fff;
  text-align: center;
}

html {
  height: 100% !important;
  /* overflow: hidden; */
}

.euros-box .small-icon {
  max-width: 90%;
}

.euros-box .table-display {
  display: table;
}

.euros-box .table-display h2 {
  display: table-cell;
  vertical-align: bottom;
  margin: 0 auto;
  text-align: right;
}

.euros-box .table-display h2 sup {
  font-size: 20px;
  vertical-align: text-top;
  font-family: "Geogrotesque-Regular";
}

.euros-box h1 sup {
  font-size: 40px;
  vertical-align: text-top;
  font-family: "Geogrotesque-Regular";
  line-height: 170px;
  position: static;
}

.euros-box h4 span.deg {
  font-size: 30px;
  vertical-align: text-top;
  font-family: "Geogrotesque-Regular";
  display: inline-block;
  margin-top: 20px;
}

.euros-box h4 span.line {
  height: 5px;
  border-radius: 4px;
  width: 135px;
  margin: 40px auto -30px;
  display: block;
}

.euros-box h4 span.interwal {
  font-size: 28px;
  /* margin-top: -85px;
  display: block; */
}

.euros-box [class*="MuiTab-textColorInherit"] {
  opacity: 1 !important;
}

.euros-box .back-btn {
  width: 10.25%;
  position: absolute;
  right: 28px;
  display: inline-block;
  border: 2px solid #ffffff;
  border-radius: 4px;
  height: 58px;
  box-sizing: border-box;
}

.euros-box .pointer {
  cursor: pointer;
}

.euros-box .cyr {
  position: absolute;
  top: 28px;
  font-size: 40px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  right: 0;
}

.euros-box .swiper-container {
  height: 98%;
  width: auto;
  background: transparent;
  box-sizing: content-box;
}

.euros-box .swiper-pagination-bullet {
  border: 2px solid #ffffff;
  padding: 5px;
}

.euros-box .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 15px;
    opacity: 1;
  }
  
  .euros-box .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: url("./pages/EurosBox/img/kolko.svg") no-repeat center center transparent;
    background-size: 100% 100%;
  }
  
  .euros-box [class*="MuiSwitch-colorPrimary"].Mui-checked {
    color: #b3b3b3 !important;
  }
  
  .euros-box [class*="MuiSwitch-colorPrimary"].Mui-checked + [class*="MuiSwitch-track"] {
    background-color: #009245 !important;
  }
  
  .euros-box [class*="MuiSwitch-switchBase"].Mui-checked + [class*="MuiSwitch-track"] {
    opacity: 1 !important;
    background-color: #009245 !important;
  }

  .euros-box .MuiSwitch-thumb {
    width: 25px;
    height: 25px;
  }
  
  .euros-box .MuiSwitch-track {
    border-radius: 11px;
  }
  
  .euros-box .MuiSwitch-switchBase {
    left: 5px;
  }
  
  .euros-box .MuiSwitch-root {
    width: 65px;
    height: 44px;
  }
  
  .euros-box [class*="MuiButtonBase-root"][class*="MuiTab-root"][class*="MuiTab-textColorInherit"].Mui-selected {
    background: none #ffffff;
    color: #000000;
  }
  
  .euros-box .MuiSlider-rail,
  .euros-box .MuiSlider-track {
    height: 25px !important;
    border-radius: 0 !important;
    border: 0 !important;
    /* top: 72% !important; */
  }

  .euros-box .MuiSlider-root {
    pointer-events: none !important;
  }
  
  .euros-box .MuiSlider-thumb {
    width: 0 !important;
    pointer-events: all !important;
  }
  
  .euros-box .MuiSlider-thumb.Mui-focusVisible,
  .euros-box .MuiSlider-thumb:hover {
    box-shadow: none !important;
  }
  
  .euros-box .MuiSlider-mark {
    display: none !important;
  }
  
  .euros-box .MuiSlider-markLabel {
    color: #ffffff !important;
    top: 10px !important;
    font-size: 19px !important;
  }
  
  .euros-box .MuiSlider-valueLabel {
    background: url("./pages/EurosBox/img/znacznik1.svg") no-repeat center center transparent !important;
    background-size: 100% auto !important;
    height: 50px !important;
    padding: 0 5px 10px !important;
    top: -2px !important;
    pointer-events: auto !important;
    box-sizing: content-box;
  }
  
  .euros-box .bottomLabel .MuiSlider-thumb:nth-child(even) .MuiSlider-valueLabel {
    background: url("./pages/EurosBox/img/znacznik1rot.svg") no-repeat center center transparent !important;
    background-size: 100% auto !important;
    padding: 10px 5px 0 !important;
    top: 83px !important;
  }
  
  .euros-box .bottomLabelInvert .MuiSlider-thumb:nth-child(odd) .MuiSlider-valueLabel {
    background: url("./pages/EurosBox/img/znacznik1rot.svg") no-repeat center center transparent !important;
    background-size: 100% auto !important;
    padding: 10px 5px 0 !important;
    top: 90px !important;
  }
  
  .euros-box .MuiSlider-valueLabelCircle {
    background: none #000000 !important;
    height: 30px !important;
    width: 30px !important;
    border-radius: 50% !important;
    line-height: 30px !important;
    margin-top: -5px !important;
  }
  .euros-box .bottomLabel .MuiSlider-thumb:nth-child(even) .MuiSlider-valueLabelCircle {
    margin-top: 5px !important;
  }
  
  .euros-box .bottomLabelInvert .MuiSlider-thumb:nth-child(odd) .MuiSlider-valueLabelCircle {
    margin-top: 5px !important;
  }
  
  .carousel .swiper-container {
    width: auto;
    height: 250px;
    background: transparent;
    font-family: 'Geogrotesque-Regular', 'Geogrotesque-SEmiBold', 'sans-serif';
  }
  
  .carousel span {
    width: auto;
    background: transparent;
    font-family: 'Geogrotesque-Regular', 'Geogrotesque-SEmiBold', 'sans-serif';
  }
  
  .euros-box .swiper-slide {
    font-size: 36px;
    color: #fff;
    display: block;
  }

  .carousel .swiper-slide {
    text-align: center;
    font-size: 36px;
    color: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    cursor: pointer;
  }

  .euros-box .swiper-slide img {
    width: auto;
    height: auto;
  }
  
  .carousel .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .carousel .swiper-slide.swiper-slide-visible.swiper-slide-prev,
  .carousel .swiper-slide.swiper-slide-visible.swiper-slide-next {
    color: slategray;
  }

  .euros-box .swiper-slide.swiper-slide-active {
    border-bottom: none;
    border-top: none;
  }

  .carousel .swiper-slide.swiper-slide-active {
    border-bottom: 2px solid rgba(255, 255, 255, 0.6);
    border-top: 2px solid rgba(255, 255, 255, 0.6);
    font-size: 56px;
    flex-shrink: 0;
  }
  
  .euros-box .modes .small-icon {
    max-height: 90px;
    line-height: normal;
    /*max-height: 106px;
    max-width: 110px;
    margin: 20px;*/
  }
  
  .euros-box .ecomode .MuiButton-root {
    border: 2px solid #ffffff !important;
    margin: 0 36px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 32px;
    line-height: 42px !important;
    border-radius: 4px !important;
    color: #ffffff !important;
    font-family: "Geogrotesque-Regular";
  }
  
  .euros-box .ecomode .MuiButton-root.active {
    border: 2px solid #ffffff !important;
    margin: 0 36px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 32px;
    line-height: 42px !important;
    border-radius: 4px !important;
    color: #000000 !important;
    font-family: "Geogrotesque-Regular";
    background: #ffffff;
  }
  
  .euros-box .ecomode .MuiButton-root.hide {
    display: none !important;
  }

  .euros-box .ecomode .MuiButtonGroup-root .MuiButtonGroup-grouped:not(:last-of-type) {
    border-top-right-radius:0;
    border-bottom-right-radius:0;
    border-right-color:transparent;
  }

  .euros-box .ecomode .MuiButtonGroup-root .MuiButtonGroup-grouped:not(:first-of-type) {
    border-top-left-radius:0;
    border-bottom-left-radius:0;
    margin-left:-1px;
  }
  
  .euros-box .ecomode .temp-button {
    border-radius: 4px;
    width: 100%;
    height: 56px;
    max-width: 200px;
    line-height: 56px;
    font-size: 32px;
    font-family: "Geogrotesque-Regular";
    margin: 10px auto;
    cursor: pointer;
    box-sizing: content-box;
  }
  
  .euros-box .ecomode .darkgreen {
    border: 2px solid #006837;
  }
  .euros-box .ecomode .lightgreen {
    border: 2px solid #8cc63f;
  }
  .euros-box .ecomode .red {
    border: 2px solid #960000;
  }
  
  .euros-box .ecomode .small-icon {
    max-height: 56px;
    margin: 15px auto !important;
    display: block;
  }
  
  .euros-box .setting-item {
    border-top: 1px solid #b3b3b3;
    border-bottom: 1px solid #b3b3b3;
    height: 70px;
    box-sizing: content-box;
  }
  
  .euros-box .setting-item p {
    padding-left: 58px;
    line-height: 70px;
  }
  
  .euros-box .setting-item [class*="MuiSwitch-root"] {
    margin-top: 17px;
  }

  .euros-box .doubleModeEdit-box {
    border-radius: 5px;
    border: 1px solid #b3b3b3;
    height: 218px;
    padding: 0;
    width: 103px;
    text-align: center;
    margin: 3px auto;
    line-height: 208px !important;
    cursor: pointer;
  }

  .euros-box-dialog .doubleModeEdit-box {
    border-radius: 5px;
    border: 1px solid #b3b3b3;
    height: 218px;
    padding: 0;
    width: 103px;
    text-align: center;
    margin: 3px auto;
    line-height: 208px !important;
    cursor: pointer;
  }
  
  .euros-box .modeEdit-box {
    border-radius: 10px;
    border: 1px solid #b3b3b3;
    height: 103px;
    padding: 0;
    width: 103px;
    text-align: center;
    margin: 3px auto;
    line-height: 103px !important;
    cursor: pointer;
  }

  .euros-box-dialog .modeEdit-box {
    border-radius: 10px;
    border: 1px solid #b3b3b3;
    height: 103px;
    padding: 0;
    width: 103px;
    text-align: center;
    margin: 3px auto;
    line-height: 103px !important;
    cursor: pointer;
  }
  
  .euros-box .anlModeEdit-box {
    border-radius: 5px;
    border: 1px solid #b3b3b3;
    height: 72px;
    padding: 0;
    width: 72px;
    text-align: center;
    margin: 3px auto;
    line-height: 72px !important;
    cursor: pointer;
  }

  .euros-box p.edit-box {
    border-radius: 5px;
    border: 1px solid #b3b3b3;
    height: 50px;
    padding: 0;
    width: 50px;
    text-align: center;
    margin: 10px auto;
    line-height: 50px !important;
    cursor: pointer;
  }
  
  p.edit-box {
    border-radius: 5px;
    border: 1px solid #b3b3b3;
    height: 50px;
    padding: 0;
    width: 50px;
    text-align: center;
    margin: 10px auto;
    line-height: 50px !important;
    cursor: pointer;
  }
  
  .euros-box p.data-box {
    border-radius: 5px;
    border: 1px solid #b3b3b3;
    height: 50px;
    padding: 0 25px;
    width: auto;
    text-align: center;
    margin: 10px 10px;
    line-height: 50px;
    cursor: pointer;
    /* display: inline-block; */
    float: right;
  }
  
  .euros-box p.data-box span {
    font-size: 14px;
    line-height: 1;
  }
  
  .euros-box p.statistics-box {
    border-radius: 5px;
    border: 1px solid #b3b3b3;
    height: 50px;
    padding: 0 15px;
    width: auto;
    text-align: center;
    margin: 10px 10px;
    line-height: 25px;
    cursor: pointer;
    /* display: inline-block; */
    float: right;
    width: 60%;
  }
  
  .euros-box p.statistics-box span {
    font-size: 18px;
    line-height: 1;
  }
  
  .euros-box .setting-item p.data-box:first-child {
    margin-right: 25px;
  }
  
  
  .euros-box {
    padding: 10px 0;
    max-width: 1024px;
    margin: 0 auto;
    position: relative;
  }

  .euros-box .anlMode-icon {
    cursor: pointer;
    max-height: 75px;
  }

  .euros-box .mode-icon {
    height: auto;
    max-height: 105px;
    cursor: pointer;
    margin: 3px auto;
  }

  .euros-box-dialog .mode-icon {
    height: auto;
    max-height: 105px;
    cursor: pointer;
    margin: 3px auto;
  }

.euros-box h1 {
  text-transform: none;
}

.euros-box h2 sup {
  line-height: 38px;
  position: static;
}

.euros-box td, .euros-box th {
  border: 2px solid #b3b3b3;
  padding: 5px;
}

.euros-box .statistics td, .euros-box .statistics th {
  padding: 16px;
}

/* ------ SCADA ------- */
/* ------ ATMO ------- */
.euros-box .atmo .st0 {
  fill: #9fc9c7;
}
.euros-box .atmo .st1 {
  fill: none;
  stroke: #f05b41;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.euros-box .atmo .st1.red{
  fill: none;
  stroke: #d62027;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.euros-box .atmo .st1.blue{
  fill: none;
  stroke: #0071BC;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.euros-box .atmo .st2 {
  fill: none;
  stroke: #ff0000;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.euros-box .atmo .st2.red{
  fill: none;
  stroke: #d62027;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.euros-box .atmo .st2.blue{
  fill: none;
  stroke: #0071BC;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.euros-box .atmo .st3 {
  fill: #808083;
}
.euros-box .atmo .st4 {
  fill: #59595c;
  stroke: none;
}
.euros-box .atmo .st5 {
  fill: #b0dedb;
  stroke: none;
}
.euros-box .atmo .st6 {
  fill: #d4ece9;
  stroke: none;
}
.euros-box .atmo .st7.red {
  fill: #f05b41;
}
.euros-box .atmo .st7.blue{
  fill: #3FA9F5;
}
.euros-box .atmo .st8 {
  fill: #4d4d4d;
}
.euros-box .atmo .st9 {
  fill: #e6e7e8;
}
.euros-box .atmo .st10 {
  fill: none;
  stroke: #d62027;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.euros-box .atmo .st10.red{
  fill: none;
  stroke: #d62027;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.euros-box .atmo .st10.blue{
  fill: none;
  stroke: #0071BC;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.euros-box .atmo .st11 {
  fill: #d62027;
}
.euros-box .atmo .st11.red{
  fill: #d62027;
}
.euros-box .atmo .st11.blue{
  fill: #0071BC !important;
}
.euros-box .atmo .st12 {
  fill: none;
  stroke: #05bcd5;
  stroke-width: 5.1072;
  stroke-miterlimit: 10;
}
.euros-box .atmo .st12.red {
  fill: none;
  stroke: #d62027;
  stroke-width: 5.1072;
  stroke-miterlimit: 10;
}
.euros-box .atmo .st12.blue {
  fill: none;
  stroke: #0071BC;
  stroke-width: 5.1072;
  stroke-miterlimit: 10;
}
.euros-box .atmo .st13 {
  fill: #5e5e5e;
}
.euros-box .atmo .st14 {
  fill: none;
  stroke: #ffffff;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
.euros-box .atmo .st15 {
  fill: none;
  stroke: #e5e5e4;
  stroke-width: 4.303;
  stroke-miterlimit: 10;
}
.euros-box .atmo .st16.green{
  fill: #009245;
}
.euros-box .atmo .st16.orange{
  fill: #FF9515;
}
.euros-box .atmo .st16.blue{
  fill: #008AAA;
}
.euros-box .atmo .st17.orange{
  fill: #f7931e;
}
.euros-box .atmo .st17.green{
  fill: #009245;
}
.euros-box .atmo .st18 {
  fill: none;
  stroke: #ffffff;
  stroke-linecap: square;
  stroke-miterlimit: 22.926;
}
.euros-box .atmo .st19 {
  fill: none;
  stroke: #ffffff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 22.9256;
}
.euros-box .atmo .st20 {
  fill: none;
  stroke: #ffffff;
  stroke-miterlimit: 10;
}
.euros-box .atmo .st21 {
  fill: #ffffff;
} 

.euros-box .lowerSourceFan {
  transform-origin: 405.4px 83.8px;
  animation-name:rotateFan;
  animation-duration:1s;
  animation-iteration-count:infinite;
  animation-timing-function: linear;
  animation-direction: reverse;
  
  -webkit-transform-origin: 405.4px 83.8px;
  -webkit-animation-name:rotateFan;
  -webkit-animation-duration:1s;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-direction: reverse;
}

.euros-box .coolingCircuiteFan {
  transform-origin: 61.3px 84.7px;
  animation-name:rotateFan;
  animation-duration:1s;
  animation-iteration-count:infinite;
  animation-timing-function: linear;
  animation-direction: reverse;
  
  -webkit-transform-origin: 61.3px 84.7px;
  -webkit-animation-name:rotateFan;
  -webkit-animation-duration:1s;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-direction: reverse;
}

.euros-box .CWUCircuitFan {
  transform-origin: 704.9px 347.8px;
  animation-name:rotateFan;
  animation-duration:1s;
  animation-iteration-count:infinite;
  animation-timing-function: linear;
  animation-direction: reverse;
  
  -webkit-transform-origin: 704.9px 347.8px;
  -webkit-animation-name:rotateFan;
  -webkit-animation-duration:1s;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-direction: reverse;
}

.euros-box .regenPumpFan_1 {
  transform-origin: 498.1px 347.8px;
  animation-name:rotateFan;
  animation-duration:1s;
  animation-iteration-count:infinite;
  animation-timing-function: linear;
  animation-direction: reverse;
  
  -webkit-transform-origin: 498.1px 347.8px;
  -webkit-animation-name:rotateFan;
  -webkit-animation-duration:1s;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-direction: reverse;
}

.euros-box .regenPumpFan_2 {
  transform-origin: 406.9px 347.8px;
  animation-name:rotateFan;
  animation-duration:1s;
  animation-iteration-count:infinite;
  animation-timing-function: linear;
  animation-direction: reverse;
  
  -webkit-transform-origin: 406.9px 347.8px;
  -webkit-animation-name:rotateFan;
  -webkit-animation-duration:1s;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-direction: reverse;
}

.euros-box .upperSourceFan {
  transform-origin: 687.9px 175.8px;
  animation-name:rotateFan;
  animation-duration:1s;
  animation-iteration-count:infinite;
  animation-timing-function: linear;
  animation-direction: reverse;
  
  -webkit-transform-origin: 687.9px 175.8px;
  -webkit-animation-name:rotateFan;
  -webkit-animation-duration:1s;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-direction: reverse;
}

.euros-box .compressor {
  transform-origin: 552.2px 32.5px;
  animation-name:rotateFan;
  animation-duration:1s;
  animation-iteration-count:infinite;
  animation-timing-function: linear;
  animation-direction: reverse;
  
  -webkit-transform-origin: 552.2px 32.5px;
  -webkit-animation-name:rotateFan;
  -webkit-animation-duration:1s;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-direction: reverse;
}

.euros-box .heatingCircuitFan {
  transform-origin: 64.4px 347.8px;
  animation-name:rotateFan;
  animation-duration:1s;
  animation-iteration-count:infinite;
  animation-timing-function: linear;
  animation-direction: reverse;
  
  -webkit-transform-origin: 64.4px 347.8px;
  -webkit-animation-name:rotateFan;
  -webkit-animation-duration:1s;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-direction: reverse;
}

.euros-box .scadaFan {
  transform-origin: 528.6px 104.6px;
  animation-name:rotateFan;
  animation-duration:1s;
  animation-iteration-count:infinite;
  animation-timing-function: linear;
  animation-direction: reverse;
  
  -webkit-transform-origin: 528.6px 104.6px;
  -webkit-animation-name:rotateFan;
  -webkit-animation-duration:1s;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-direction: reverse;
}

.euros-box .scadaBuforFan {
  transform-origin: 639.7px 362.2px;
  animation-name:rotateFan;
  animation-duration:1s;
  animation-iteration-count:infinite;
  animation-timing-function: linear;
  animation-direction: reverse;

  -webkit-transform-origin: 639.7px 362.2px;
  -webkit-animation-name:rotateFan;
  -webkit-animation-duration:1s;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-direction: reverse;
}

.euros-box .scadaBigFan {
  transform-origin: center;
  transform-box: fill-box;
  animation-name:rotateFan;
  animation-duration:3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: reverse;

  transform-origin: center;
  transform-box: fill-box;
  -webkit-animation-name:rotateFan;
  -webkit-animation-duration:3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-direction: reverse;
}

.euros-box .scadaCircuit2Fan {
  transform-origin: 332.4px 269px;
  animation-name:rotateFan;
  animation-duration:1s;
  animation-iteration-count:infinite;
  animation-timing-function: linear;
  animation-direction: reverse;

  -webkit-transform-origin: 332.4px 269px;
  -webkit-animation-name:rotateFan;
  -webkit-animation-duration:1s;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-direction: reverse;
}

@keyframes rotateFan {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@-webkit-keyframes rotateFan {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.euros-box .text-scada {
  fill: #ffffff;
  font-size: 24px !important;
  font-family: "Geogrotesque-Regular" !important;
}

.euros-box .text-scada2 {
  fill: #ffffff;
  font-size: 240px !important;
  font-family: "Geogrotesque-Regular" !important;
}

.euros-box .text-scada.middle {
  font-size: 30px !important;
  font-weight: 600 !important;
  font-family: "Geogrotesque-SemiBold" !important;
}

.euros-box .text-scada.big {
  font-size: 46px !important;
  font-weight: 600 !important;
  font-family: "Geogrotesque-SemiBold" !important;
}

.euros-box .text-scada.middle.bar {
  font-size: 25px !important;
  font-weight: 200 !important;
  font-family: "Geogrotesque-SemiBold" !important;
}

.euros-box .text-centering {
  display: flex; 
  align-items: center;
}

.euros-box-dialog .text-centering {
  display: flex; 
  align-items: center;
}

.euros-box .circulation-container {
  border-top: 2px solid #b3b3b3;
  position: relative; 
  top: 5px; 
  padding-bottom: 0; 
  padding-top: 15px; 
}

/* ------ GEO ------- */

/* .euros-box .geo .st0{fill:#9FC9C7;}
.euros-box .geo .st1{fill:#B0DEDB;}
.euros-box .geo .st2{fill:#D4ECE9;}
.euros-box .geo .st3{fill:#3FA9F5;}
.euros-box .geo .st4{fill:#F05B41;}
.euros-box .geo .st5{fill:none;stroke:#E5E5E4;stroke-width:3.9204;stroke-miterlimit:2.2252;}
.euros-box .geo .st6.orange{fill:#F7931E;}
.euros-box .geo .st6.green{fill:#009245;}
.euros-box .geo .st7{fill:#D62027;}
.euros-box .geo .st8.orange{fill:#F7931E;}
.euros-box .geo .st8.green{fill:#009245;}
.euros-box .geo .st8.blue{fill:#0071BC;}
.euros-box .geo .st9{fill:#0071BC;}
.euros-box .geo .st10{fill:#FF0000;}
.euros-box .geo .st11{fill:none;stroke:#D62027;stroke-width:4.5553;stroke-miterlimit:2.2252;}
.euros-box .geo .st12{fill:#FFFFFF;}
.euros-box .geo .st13{fill:#808083;}
.euros-box .geo .st14{fill:#4D4D4D;}
.euros-box .geo .st15{fill:#E6E7E8;}
.euros-box .geo .st16{fill:#59595C;}
.euros-box .geo .st17{fill:none;stroke:#FFFFFF;stroke-width:1.3519;stroke-linecap:square;stroke-miterlimit:2.2252;}
.euros-box .geo .st18{fill:none;stroke:#FFFFFF;stroke-width:1.3519;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2.2252;}
.euros-box .geo .st19{fill:none;stroke:#FFFFFF;stroke-width:1.4369;stroke-miterlimit:2.2252;} */

.euros-box .text-scada.grey { fill:#5c5b5b;}
.euros-box .text-scada.white { fill:rgb(255, 255, 255);}

/* SCADA TEST */

.euros-box .fil12 {fill:black;fill-rule:nonzero}
.euros-box .fil13 {fill:#0071BC;fill-rule:nonzero}
.euros-box .fil1 {fill:#009245;fill-rule:nonzero}
.euros-box .fil6 {fill:#3FA9F5;fill-rule:nonzero}
.euros-box .fil14 {fill:#4D4D4D;fill-rule:nonzero}
.euros-box .fil10 {fill:#59595C;fill-rule:nonzero}
.euros-box .fil9 {fill:#808083;fill-rule:nonzero}
.euros-box .fil7 {fill:#9FC9C7;fill-rule:nonzero}
.euros-box .fil4 {fill:#B0DEDB;fill-rule:nonzero}
.euros-box .fil5 {fill:#D4ECE9;fill-rule:nonzero}
.euros-box .fil2 {fill:#D62027;fill-rule:nonzero}
.euros-box .fil0 {fill:#E5E5E4;fill-rule:nonzero}
.euros-box .fil15 {fill:#E6E7E8;fill-rule:nonzero}
.euros-box .fil8 {fill:#F05B41;fill-rule:nonzero}
.euros-box .fil3 {fill:#F7931E;fill-rule:nonzero}
.euros-box .fil11 {fill:red;fill-rule:nonzero}
.euros-box .fil16 {fill:white;fill-rule:nonzero}
.euros-box .fil17 {fill:white;fill-rule:nonzero}

/* SCADA CIRCUIT 2 */

/* .euros-box .scadaCircuit2 .st0{display:none;}
.euros-box .scadaCircuit2 .st1{fill:none;stroke:#D62027;stroke-width:5;stroke-miterlimit:10;}
.euros-box .scadaCircuit2 .st2{fill:none;stroke:#0071BC;stroke-width:5;stroke-miterlimit:10;}
.euros-box .scadaCircuit2 .st3{opacity:0.3;fill:none !important;stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;}
.euros-box .scadaCircuit2 .st4{fill:none;stroke:url(#podlogowka_00000075873222893764047640000003632571984878527664_);stroke-width:5;stroke-miterlimit:10;}
.euros-box .scadaCircuit2 .st5{fill:#D62027;stroke: none;}
.euros-box .scadaCircuit2 .st6{fill:#FFFFFF;stroke: none;}
.euros-box .scadaCircuit2 .st7{fill:none;stroke:#E5E5E4;stroke-width:4.303;stroke-miterlimit:10;}

.euros-box .scadaCircuit2 .st8{fill:#009245 !important;}
.euros-box .scadaCircuit2 .st8.green{fill: #009245 !important;}
.euros-box .scadaCircuit2 .st8.orange{fill: #FF9515 !important;}
.euros-box .scadaCircuit2 .st8.blue{fill: #008AAA !important;}

.euros-box .scadaCircuit2 .cls-1{fill:#fff;}
.euros-box .scadaCircuit2 .cls-2{stroke-linecap:round;stroke:#fff;stroke-linejoin:round;}
.euros-box .scadaCircuit2 .cls-3{stroke-linecap:square;stroke:#fff;stroke-miterlimit:22.93;}
.euros-box .scadaCircuit2 .cls-5{fill:#f1f1f2;} */

.euros-box .belka .cls-1{fill:#fff;}
.euros-box .belka .cls-2{stroke-linecap:round;stroke:#fff;stroke-linejoin:round;}
.euros-box .belka .cls-3{stroke-linecap:square;stroke:#fff;stroke-miterlimit:22.93;}
.euros-box .belka .cls-5{fill:#f1f1f2;}
.euros-box .cls-6{
  font-family: 'Geogrotesque-SemiBold';
  fill:#f1f1f2;
  font-size: 14px;
  
}

/*scada - dodatkowe obiegi */

.euros-box .cls-6 {
  stroke: url(#linear-gradient-3);
}

/* SCADA CIRCUIT 2+ */

.euros-box .scadaCircuit2 .st0{fill:none;stroke:#D62027;stroke-width:4.922;stroke-miterlimit:10;}
.euros-box .scadaCircuit2 .st1{fill:none;stroke:#0071BC;stroke-width:4.922;stroke-miterlimit:10;}
.euros-box .scadaCircuit2 .st2{fill:#D62027;stroke:none;}
.euros-box .scadaCircuit2 .st3{fill:#0071BC !important;}
.euros-box .scadaCircuit2 .st4{fill:none;stroke:url(#SVGID_1_);stroke-width:4.922;stroke-miterlimit:10;}
.euros-box .scadaCircuit2 .st5{fill:#FFFFFF;stroke:none;}
.euros-box .scadaCircuit2 .st6{fill:none;stroke:url(#SVGID_00000150093591325766559220000013045322642496491928_);stroke-width:4.922;stroke-miterlimit:10;}
.euros-box .scadaCircuit2 .st7{fill:none;stroke:url(#SVGID_00000072271835258539320580000004126037190684824984_);stroke-width:4.922;stroke-miterlimit:10;}
.euros-box .scadaCircuit2 .st8{opacity:0.3;fill:none !important;stroke:#FFFFFF;stroke-width:1.9688;stroke-miterlimit:10;}
.euros-box .scadaCircuit2 .st9{fill:#F1F1F2 !important;}
.euros-box .scadaCircuit2 .st10{font-family:'Geogrotesque-Regular';stroke:none;}
.euros-box .scadaCircuit2 .st11{font-size:25.1374px;}
.euros-box .scadaCircuit2 .cls-1{fill:#fff;}
.euros-box .scadaCircuit2 .cls-2{stroke-linecap:round;stroke:#fff;stroke-linejoin:round;}
.euros-box .scadaCircuit2 .cls-3{stroke-linecap:square;stroke:#fff;stroke-miterlimit:22.93;}
.euros-box .scadaCircuit2 .cls-5{fill:#f1f1f2;}
.euros-box .scadaCircuit2 .st8.orange{fill:#F7931E;}
.euros-box .scadaCircuit2 .st8.green{fill:#009245;}
.euros-box .scadaCircuit2 .st8.blue{fill:#008AAA;;}


/* ------ GEO ------- */

.euros-box .geo .st0{display:none;}
.euros-box .geo .st1{display:inline;}
.euros-box .geo .st2{fill:none !important;stroke:#D62027 !important;stroke-width:4.9145;stroke-miterlimit:10;}
.euros-box .geo .st3{fill:#D62027 !important;}
.euros-box .geo .st4{fill:none !important;stroke:#E5E5E4 !important;stroke-width:4.2294;stroke-miterlimit:10;}
.euros-box .geo .st5{fill:#009245 !important;}
.euros-box .geo .st6{fill:#FFFFFF !important;}
.euros-box .geo .st7{fill:#F7931E !important;}
.euros-box .geo .st8{fill:#B0DEDB !important;}
.euros-box .geo .st9{fill:#D4ECE9 !important;}
.euros-box .geo .st10{fill:#3FA9F5 !important;}
.euros-box .geo .st11{fill:#9FC9C7 !important;stroke: none !important;}
.euros-box .geo .st12{fill:#808083 !important;}
.euros-box .geo .st13{fill:#59595C !important;}
.euros-box .geo .st14{fill:#F05B41 !important;}
.euros-box .geo .st15{fill:none !important;stroke:#FF0000 !important;stroke-width:4.9145;stroke-miterlimit:10;}
.euros-box .geo .st16{fill:none !important;stroke:#0071BC !important;stroke-width:4.9145;stroke-miterlimit:10;}
.euros-box .geo .st17{fill:#4D4D4D !important;}
.euros-box .geo .st18{fill:#E6E7E8 !important;}
.euros-box .geo .st19{fill:#0071BC !important;stroke: none !important;}
.euros-box .geo .st20{font-family:'Geogrotesque-SemiBold';}
.euros-box .geo .st21{font-size:29.1934px;}
.euros-box .geo .st22{fill:none !important;stroke:#FFFFFF !important;stroke-linecap:square;stroke-miterlimit:22.926;}
.euros-box .geo .st23{fill:none !important;stroke:#FFFFFF !important;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256;}
.euros-box .geo .st24{fill:none !important;stroke:#FFFFFF !important;stroke-width:1.4369;stroke-miterlimit:2.2252;}
.euros-box .geo .st25{fill:none !important;stroke:#FFFFFF !important;stroke-width:3;stroke-linecap:round;stroke-miterlimit:10;}
.euros-box .geo .st26{display:inline;fill:#FFFFFF !important;}
.euros-box .geo .st27{font-size:38.2551px;}
.euros-box .geo .st28{font-size:29.7016px;}
.euros-box .geo .st29{display:inline;fill:#F1F1F2 !important;}
.euros-box .geo .st30{font-family:'Geogrotesque-Regular';}
.euros-box .geo .st31{font-size:25.536px;}
.euros-box .geo .st8.orange{fill:#F7931E !important;}
.euros-box .geo .st8.green{fill:#009245 !important;}
.euros-box .geo .st8.blue{fill:#008AAA !important;}
.euros-box .geo .st2.red{fill:none !important;stroke:#D62027 !important;stroke-width:4.9145;stroke-miterlimit:10;}
.euros-box .geo .st2.blue{fill:none !important;stroke:#0071BC !important;stroke-width:4.9145;stroke-miterlimit:10;}
.euros-box .geo .blue{fill:#0071BC !important;}
.euros-box .geo .red{fill:#D62027 !important;}
.euros-box .geo .wymienniki .red{fill:#F05B41 !important;}
.euros-box .geo .wymienniki .blue{fill:#3FA9F5 !important;}

/* ------ NCBR ------- */

.euros-box .ncbr .st0{display:none;}
.euros-box .ncbr .st1{display:inline;}
.euros-box .ncbr .st2{fill:none !important;stroke:#F7931E;stroke-width:5.1016;stroke-miterlimit:10;}
.euros-box .ncbr .st3{fill:none !important;stroke:#E5E5E4;stroke-width:4.3904;stroke-miterlimit:10;}
.euros-box .ncbr .st4{fill:#F7931E !important;stroke:none;}
.euros-box .ncbr .st5{fill:#FFFFFF !important;stroke:none;}
.euros-box .ncbr .st6{font-family:'Geogrotesque-SemiBold';stroke:none;}
.euros-box .ncbr .st7{font-size:39.0324px;}
.euros-box .ncbr .st8{font-size:30.3051px;}
.euros-box .ncbr .st9{font-size:26.0548px;}
.euros-box .ncbr .st10{fill:none !important;stroke:#FFFFFF;stroke-width:2.2606;stroke-linecap:round;stroke-miterlimit:10;}
.euros-box .ncbr .st11{fill:none !important;stroke:#F7931E;stroke-width:8.1625;stroke-linecap:round;stroke-miterlimit:10;}
.euros-box .ncbr .st12{fill:#009245 !important;stroke:none;}
.euros-box .ncbr .st13{fill:none !important;stroke:#009245;stroke-width:8.1625;stroke-linecap:round;stroke-miterlimit:10;}
.euros-box .ncbr .st14{fill:#3FA9F5 !important;}
.euros-box .ncbr .st15{fill:none !important;stroke:#3FA9F5;stroke-width:8.1625;stroke-linecap:round;stroke-miterlimit:10;}
.euros-box .ncbr .st16{fill:#9FC9C7 !important;}
.euros-box .ncbr .st17{fill:#B0DEDB !important;}
.euros-box .ncbr .st18{fill:#D4ECE9 !important;}
.euros-box .ncbr .st19{fill:none !important;stroke:#F05B41;stroke-width:5.1016;stroke-miterlimit:10;}
.euros-box .ncbr .st20{fill:none !important;stroke:#FF0000;stroke-width:5.1016;stroke-miterlimit:10;}
.euros-box .ncbr .st21{fill:#F05B41 !important;}
.euros-box .ncbr .st22{fill:none !important;stroke:#D62027;stroke-width:5.1016;stroke-miterlimit:10;}
.euros-box .ncbr .st23{fill:#D62027 !important;}
.euros-box .ncbr .st24{fill:none !important;stroke:#0071BC;stroke-width:5.1016;stroke-miterlimit:10;}
.euros-box .ncbr .st25{fill:#0071BC !important;stroke:none;}
.euros-box .ncbr .st26{fill:#F1F1F2 !important;}
.euros-box .ncbr .st27{font-family:'Geogrotesque-Regular';}
.euros-box .ncbr .st28{display:inline;fill:#F7931E !important;stroke:none;}
.euros-box .ncbr .st29{fill:none !important;stroke:#FFFFFF;stroke-width:1.0203;stroke-miterlimit:22.926;}
.euros-box .ncbr .st30{fill:#808083 !important;}
.euros-box .ncbr .st31{fill:#59595C !important;}
.euros-box .ncbr .st32{fill:#4D4D4D !important;}
.euros-box .ncbr .st33{fill:#E6E7E8 !important;}

/* ALEGIONELLA */

.euros-box .st0{fill:#fff;}

.MuiDataGrid-root .user-table-header .MuiDataGrid-columnHeaderTitle {
  font-weight: 700
}

.user-table-header {
  background-color: #f5f5f5;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  outline: 0;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderWrapper {
  outline: 0;
}

.MuiDataGrid-cell .MuiInput-root {
  padding: 0;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  padding: 0 !important;
}

.MuiTableCell-root.MuiTableCell-body {
  vertical-align: middle;
}

.euros-box .bateria-sprawna {
  margin-top: 29px;
  margin-left: -46px;
  width: 50px !important; 
  position: absolute;
}

.euros-box .bateria-uszkodzona {
  margin-top: 23px;
  margin-left: -46px;
  width: 50px !important; 
  position: absolute;

  animation: blink 0.8s steps(2, start) infinite;
  -webkit-animation: blink 0.8s steps(2, start) infinite;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

.euros-box .MuiGrid-item {
  position: static;
}

.MuiGridFilterForm-root {
  display: block !important;
}



/* EUROS MONITOR */
.euros-monitor {
  background-color: #00509b;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.euros-monitor body::-webkit-scrollbar {
  display: none;
}

.euros-monitor {
  text-align: center;
  margin: 10px;
}

.euros-monitor .App-logo {
  height: 40vmin;
  pointer-events: none;
}

.euros-monitor .MuiList-root a:visited, .euros-monitor .MuiList-root a:link, .euros-monitor .MuiList-root a:hover {
  color: #212121;
  text-decoration: none;
}

.euros-monitor a.cutomer-app:visited, .euros-monitor a.cutomer-app:link, .euros-monitor a.cutomer-app:hover {
  color: #ffffff;
  text-decoration: none;
}

@media (prefers-reduced-motion: no-preference) {
  .euros-monitor .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.euros-monitor .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.euros-monitor .App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.euros-monitor-carousel {
  text-align: left;
  background-color: white;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: normal;
  line-height: normal;
}

.euros-monitor.euros-monitor-carousel {
  margin: 0;
}

.euros-monitor .carousel-input-click2 {
  min-width: 40px;
  max-width: 60%;
  height: 40px;
  background: #fff;
  border: 1px solid #005492;
  border-radius: 5px;
  line-height: 40px !important;
  text-align: center;
  cursor: pointer;
  margin: 0 15px;
  padding: 0 15px;
  white-space: nowrap;
}

.euros-monitor .carousel-input-click-text2 {
  line-height: 40px !important;
  text-align: center;
  color: #005492;
  cursor: pointer;
  margin: 0 15px !important;
}

.euros-monitor .carousel-input-click {
  min-width: 40px;
  height: 40px;
  background: #fff;
  border: 1px solid #005492;
  border-radius: 5px;
  line-height: 40px !important;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  margin: 0 15px;
  padding: 0 15px;
  box-sizing: content-box;
  white-space: nowrap;
}

.euros-monitor .ext-carousel-input-click { 
  width: 40px; 
  height: 40px; 
  line-height: 40px !important; 
  text-align: center; 
  cursor: pointer; 
  display: inline-block; 
  white-space: nowrap; 
} 

.euros-monitor .carousel-input-click-text {
  line-height: 40px !important;
  text-align: center;
  color: #005492;
  cursor: pointer;
  display: inline-block;
  margin: 0 15px !important;
}

.euros-monitor .carousel span { 
  width: auto; 
  background: transparent; 
  font-family: 'Geogrotesque-Regular', 'Geogrotesque-SEmiBold', 'sans-serif'; 
}

.euros-monitor .swiper-container {
  width: 100%;
  height: 150px;
  background: #fff;
}

.euros-monitor .swiper-slide {
  text-align: center;
  font-size: 18px !important;
  color: #000;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
}

.euros-monitor .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}

.euros-monitor .swiper-slide.swiper-slide-visible.swiper-slide-prev, .swiper-slide.swiper-slide-visible.swiper-slide-next {
color: slategray;
}
.euros-monitor .swiper-slide.swiper-slide-active {
  border-bottom: 1px solid #005492;
  border-top: 1px solid #005492;
}

.euros-monitor .gray-bg {
  background-color: #80808059;
  margin: 15px auto 30px;
  padding: 0 15px;
}

.euros-monitor .gray-bg p {
  display: inline-block;
  margin: 5px auto;
  line-height: 26px;
  font-size: 16px;
  font-weight: normal;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  box-sizing: content-box;
}

.euros-monitor .mode-icon {
  height: 30px;
  margin: 4px auto;
}

.euros-monitor .gray-bg .mode-icon {
  cursor: pointer;
}

.euros-monitor .setting-item {
  border-top: 1px solid #b3b3b3;
  border-bottom: 1px solid #b3b3b3;
  height: 70px;
}

.euros-monitor .setting-item p {
  padding-left: 58px;
  line-height: 70px;
}

.euros-monitor p.data-box {
  border-radius: 5px;
  border: 1px solid #b3b3b3;
  height: 50px;
  padding: 0 25px;
  width: auto;
  text-align: center;
  margin: 10px 10px;
  line-height: 50px;
  cursor: pointer;
  /* display: inline-block; */
  float: left;
}

.euros-monitor .setting-item p.data-box:first-child {
  margin-right: 25px;
}

.euros-monitor .vertical-centering {
  display: flex;
  align-items: center;
}

.euros-monitor .errorlist-spacing {
  margin: 2px 0
}

.euros-monitor .errorlist-ok {
  border: 2px solid green;
  padding-left: 4px;
}

.euros-monitor .errorlist-error {
  border: 2px solid red;
  padding-left: 4px;
}

@keyframes pulsacja {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.euros-monitor .pulsowanie {
  animation: pulsacja 2s infinite;
}

.euros-monitor .aside-panel {
  position: sticky;
  top: 10px;
}

.euros-monitor .aside-panel-navigation {
  width: 95% !important;
  max-width: 79.46px !important;
}

.euros-monitor .aside-panel-box {
  position: relative;
  border: 2px solid #fff;
  margin-top: 9% !important;
  border-radius: 10px;
  padding: 2px;
  width: 95%;
  max-width: 79.46px;
  max-height: 79.46px;
  aspect-ratio: 1 / 1;
}

@media screen and (min-width: 1025px) {
  .euros-monitor .aside-panel-box {
    max-width: 80px;
    max-height: 80px;
    margin-top: 10% !important;
  }
  .euros-monitor .aside-panel-navigation {
    max-width: 80px !important;
  }
}

@media screen and (min-width: 1140px) {
  .euros-monitor .aside-panel-box {
    max-width: 90px;
    max-height: 90px;
  }
  .euros-monitor .aside-panel-navigation {
    max-width: 90px !important;
  }
}

.euros-monitor .aside-panel-text {
  color: #ffffff;
  font-weight: 800 !important;
}

.euros-monitor .PV-icon {
  width: 70%;
  margin-top: 6%;
}

.euros-monitor .aside-panel-mode-icons {
  width: 30%;
}

.euros-monitor .hourglass {
  margin-top: 19%;
}

.euros-monitor .chlodzenie {
  margin-bottom: 7%;
  margin-top: 26%;
}

.euros-monitor .grzanie {
  margin-bottom: 7%;
  margin-top: 23%;
}

.euros-monitor .kropla {
  margin-top: 24%;
}

.euros-monitor .defrost {
  margin-bottom: 9%;
  margin-top: 26%;
}

.euros-monitor .aside-panel-link {
  width: 30%;
  margin-bottom: 18%;
  padding: 0 2%;
}

.euros-monitor .modulacja-fix-geo {
  width: 64%;
  margin-top: 25%;
}

.euros-monitor .modulacja-fix-atmo {
  width: 30%;
  margin-top: 5%;
}

.euros-monitor .modulacja-reg-geo {
  width: 80%;
  margin-top: 25%;
}

.euros-monitor .modulacja-reg-atmo {
  width: 38%;
  margin-top: 5%;
}

.euros-monitor .pompka {
  width: 70%;
  margin-top: 12%;
}

.euros-monitor .timer {
  width: 50%;
  margin-bottom: 10%;
  margin-right: 2%; 
}

.euros-monitor .delta {
  width: 40%;
  margin-top: 21%;
}

.euros-monitor .legionella {
  width: 44%;
  margin-top: 10%;
}

.euros-monitor .legionella-bateria {
  width: 35%;
  margin-top: 10%;
}

.euros-monitor .bateria-uszkodzona {
  width: 45%;
  margin-bottom: 30%;
  margin-top: 10%;
  margin-right: 2%;
}

.euros-monitor .bateria-sprawna {
  width: 45%;
  margin-bottom: 38%;
  margin-top: 15%;
  margin-right: 2%;
}

.euros-monitor img {
  vertical-align: baseline;
}

.euros-monitor .time-mode p {
  margin: 16px 0;
  font-size: 16px;
  font-weight: normal;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  box-sizing: content-box;
}

.euros-monitor .circulation p {
  margin: 16px 0;
  font-size: 16px;
  font-weight: normal;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  box-sizing: content-box;
}

.euros-monitor .cls-1 { 
  fill: none; 
  stroke: #939393; 
  stroke-miterlimit: 10; 
  stroke-width: 3px; 
} 
 
.euros-monitor .cls-2 { 
  fill: none; 
  stroke: #231f20; 
  stroke-miterlimit: 10; 
  stroke-width: 3px; 
} 

.euros-monitor label {
  margin: 0;
}

.registerListDescription .MuiInput-input {
  text-align: left;
  display:flex;
  flex-direction: column;
  justify-content: center;
}

.registerListDescription .MuiInput-root {
  margin-bottom: 0;
}

.desc-styling {
  white-space: normal !important;
  line-height: 1 !important;
}

.desc-styling2 {
  white-space: normal !important;
  line-height: 1.2 !important;
  display: flex !important;
  align-items: center;
}

.data-grid-min::after{
  content: "\a";
  white-space: pre;
}

.partnerListItem {
  cursor: pointer;
  color: white;
}

.partnerListItem:hover {
  cursor: pointer;
  color: black;
  background-color: #efefef;
}

.MuiGridPanel-root .MuiSwitch-root {
  overflow: visible;
}

/* ------ Wykres statystyk ----- */
.chart .stat0{fill:none;stroke:#FFFFFF;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.chart .stat1{fill:#FFFF00;}
.chart .stat2{fill:#FF9515;}
.chart .stat3{fill:#008AAA;}
.chart .stat4{fill:#FFFFFF;}
.chart .stat5{font-family:'Geogrotesque-Regular';}
.chart .stat6{font-size:17.5px;}
.chart .stat7{fill:#BC6A11;}
.chart .stat8{font-size:17.5px;}
.chart .stat9{font-family:'Geogrotesque-Regular';}
.chart .stat10{font-size:55px;}

.euros-box .frameMeter {
  border: 1px solid #ffffff !important;
  margin: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 10px !important;
  color: #ffffff !important;
  font-family: "Geogrotesque-Regular";
  min-height: 430px;
  padding: 5px;
}

.euros-box .frameMeter #guagemeter {
  margin: 30px 0;
}

.euros-box #guagemeter.bigGauge .value-text {
  transform: translate(108px, 115px) !important;
}

.euros-box #guagemeter.bigGauge .value-text text{
  font-size: 32px !important;
}

.euros-box #guagemeter .value-text {
  transform: translate(78px, 82px);
}


.euros-box .meterIcon {
  display: block;
  margin: 5px 15px;
  box-sizing: border-box;
  float: left;
  max-height: 35px;
}

.euros-box .meterTitle {
  margin: 0 15px !important;
  float: right;
  box-sizing: border-box;
  line-height: 45px;
  font-size: 17px;
}

.euros-box .parametry-icon {
  border: 1px solid #ffffff !important;
  margin: 10px auto;
  border-radius: 10px !important;
  width: 105px;
  height: 105px;
  cursor: pointer;
}

.euros-box .parametry-icon img {
  max-height: 70px;
}

.euros-box .parametry-icon span {
  color: #fff !important;
  text-decoration: none !important;
  font-size: 18px;
  display: block;
}

.euros-box .frameMeter a {
  text-decoration: none !important;
}

.euros-box .outerSubArc, .tick-value, .tick-line {
  display: none;
}

.euros-box .subArc {
  border-radius: 60%;
}

.euros-box .revers .subArc {
  transform: rotateY(180deg);
}

.euros-box .aplaMeter {
  position: absolute; 
  top: 0; 
  right: 0; 
  background: rgba(0, 0, 0, 0.75); 
  width: 100%; 
  height: 82%;
}

.euros-box .aplaMeter.hide {
  display: none !important;
}